import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'

const routes = [
  {
    path: '/avaliacoes',
    name: 'Avaliacoes',
    component: () => import('../views/About.vue')
  },
  {
    path: '/contato',
    name: 'Contato',
    component: () => import('../components/Contato.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/produtos',
    name: 'Produtos',
    component: () => import('../views/About.vue')
  },
  {
    path: '/quemsomos',
    name: 'QuemSomos',
    component: () => import('../views/About.vue')
  },
  {
    path: '/servicos',
    name: 'Servicos',
    component: () => import('../components/Servicos.vue')
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: () => import('../components/Portfolio.vue')
  },
  {
    path: '/diferenciais',
    name: 'Diferenciais',
    component: () => import('../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
  }
})

export default router
