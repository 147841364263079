<template>
  <section>
    <nav>
      <div class="logo">
        <img src="@/assets/logo.png" alt="Logo Image">
      </div>
      <div class="hamburger" v-on:click="opennav">
        <div class="line1"></div>
        <div class="line2"></div>
        <div class="line3"></div>
      </div>
      <ul class="nav-links art">
        <li v-on:click="opennav"><router-link to="/">Home</router-link></li>
        <li v-on:click="opennav"><router-link to="/quemsomos">Quem Somos</router-link></li>
        <li v-on:click="opennav"><router-link to="/servicos">Serviços</router-link></li>
        <li v-on:click="opennav"><router-link to="/portfolio">Portfólio</router-link></li>
        <li v-on:click="opennav"><router-link to="/contato">Contato</router-link></li>
      </ul>
    </nav>
  </section>
</template>
<script>
export default {
  methods: {
    opennav () {
      const hamburger = document.querySelector('.hamburger')
      const navLinks = document.querySelector('.nav-links')
      navLinks.classList.toggle('open')
      hamburger.classList.toggle('toggle')
    }
  }
}
</script>
<style lang="scss" scoped>
nav {
  height: 6rem;
  min-width: 100% !important;
  background-color: #fff;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

/*Styling logo*/
.logo img {
  position: absolute;
  left: 3%;
  top: 0%;
  height: 6rem;
  width: 8rem
}

/*Styling Links*/
.nav-links {
  display: flex;
  list-style: none;
  width: 88vw;
  padding: 0 0.7vw;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;
  margin-left: 7rem!important;
  @media only screen and (max-width: 600px) {
    margin: 0!important;
  }
  color: #16153D
}

.nav-links li a {
  color: #16153D
}

.nav-links li a:hover {
  color: orange;
}

.nav-links li {
  position: relative;
}

.nav-links li a::before {
  content: "";
  display: block;
  height: 3px;
  width: 0%;
  background-color: orange;
  position: absolute;
  bottom: -7px;
  transition: all ease-in-out 250ms;

}

.nav-links li a:hover::before {
  width: 98%;
}

/*Styling Buttons*/
.login-button {
  background-color: transparent;
  border: 1.5px solid #f2f5f7;
  border-radius: 2em;
  padding: 0.6rem 0.8rem;
  margin-left: 2vw;
  font-size: 1rem;
  cursor: pointer;
}

.login-button:hover {
  color: #131418;
  background-color: #f2f5f7;
  border: 1.5px solid #f2f5f7;
  transition: all ease-in-out 350ms;
}

.join-button {
  color: #131418;
  background-color: #61DAFB;
  border: 1.5px solid #61DAFB;
  border-radius: 2em;
  padding: 0.6rem 0.8rem;
  font-size: 1rem;
  cursor: pointer;
}

.join-button:hover {
  color: #f2f5f7;
  background-color: transparent;
  border: 1.5px solid #f2f5f7;
  transition: all ease-in-out 350ms;
}

/*Styling Hamburger Icon*/
.hamburger div {
  width: 30px;
  height: 3px;
  background: #111111;
  margin: 5px;
  transition: all 0.3s ease;
}

.hamburger {
  display: none;
}

/*Stying for small screens*/
@media screen and (max-width:800px) {
  nav {
    position: fixed;
    top: 0;
    z-index: 3
  }

  .hamburger {
    display: block;
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 50%;
    transform: translate(-5%, -50%);
    z-index: 2;
    transition: all 0.7s ease;
  }

  .nav-links {
    position: fixed;
    background: #fff;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    clip-path: circle(50px at 90% -20%);
    -webkit-clip-path: circle(50px at 90% -10%);
    transition: all 1s ease-out;
    pointer-events: none;
    color: #111111
  }

  .nav-links.open {
    clip-path: circle(1000px at 90% -10%);
    -webkit-clip-path: circle(1000px at 90% -10%);
    pointer-events: all;
  }

  .nav-links li {
    opacity: 1;
  }

  .nav-links li:nth-child(1) {
    transition: all 0.5s ease 0.2s;
  }

  .nav-links li:nth-child(2) {
    transition: all 0.5s ease 0.4s;
  }

  .nav-links li:nth-child(3) {
    transition: all 0.5s ease 0.6s;
  }

  .nav-links li:nth-child(4) {
    transition: all 0.5s ease 0.7s;
  }

  .nav-links li:nth-child(5) {
    transition: all 0.5s ease 0.8s;
  }

  .nav-links li:nth-child(6) {
    transition: all 0.5s ease 0.9s;
    margin: 0;
  }

  .nav-links li:nth-child(7) {
    transition: all 0.5s ease 1s;
    margin: 0;
  }

  li.fade {
    opacity: 1;
  }
}

/*Animating Hamburger Icon on Click*/
.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
  transition: all 0.7s ease;
  width: 0;
}

.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}</style>
