<template>
  <div class='art div-footer'>
    <a href="https://api.whatsapp.com/send?phone=5519992114069&amp;text=Olá%20Campinas%20Redes%20de%20Proteção,%20gostaria%20de%20solicitar%20um%20orçamento."
      class="btn-whats conversion"><img src="@/assets/whatsapp-32.svg"></a>
    <footer class="footer">
      <section>
      <div class=" col-sm-4 col-md col-sm-4  col-12 col">
        <h5 class="txt-normal">Sobre nos</h5>
        <p class="mb10">A Campinas Redes de Proteção é uma empresa especializada na instalação de redes de proteção, oferecendo soluções seguras e confiáveis para garantir a segurança de pessoas e animais em áreas de risco. Com anos de experiência no mercado, a empresa utiliza materiais de alta qualidade e tecnologia avançada para garantir a durabilidade e eficiência de seus produtos. Conte com a Campinas Redes de Proteção para proteger o que mais importa para você.</p>
      </div>
      <div class=" col-sm-4 col-md  col-6 col">
        <h5 class="txt-normal">Serviços</h5>
        <ul class="footer_ul_amrc">
          <li><router-link to="/servicos">Redes de Proteção para Janelas</router-link></li>
          <li><router-link to="/servicos">Redes de Proteção para Varandass</router-link></li>
          <li><router-link to="/servicos">Redes de Proteção Para Campos de futebol</router-link></li>
          <li><router-link to="/servicos">Redes de Proteção Para Quadras poliesportivas</router-link></li>
          <li><router-link to="/servicos">Redes de Proteção Para Playgrounds</router-link></li>
          <li><router-link to="/servicos">Redes de Proteção Anti-pássaros</router-link></li>
          <li><router-link to="/servicos">Redes de Proteção para Animais</router-link></li>
          <li><router-link to="/servicos">Redes de Proteção Para Cercamento de piscinas</router-link></li>
          <li><router-link to="/servicos">Redes de Proteção para cobertura de piscinas</router-link></li>

        </ul>
      </div>
      <div>
        <ul class=" col-sm-4 col-md  col-6 col">
          <h5 class="txt-normal">Contatos</h5>
          <li>
            <p><a
                href="https://api.whatsapp.com/send?phone=5519992114069&amp;text=Olá%20Campinas%20Redes%20de%20Proteção,%20gostaria%20de%20solicitar%20um%20orçamento."><span
                  style="color:#ccc;font-size:18px"><img src="@/assets/whatsapp-32.svg" width="18" height="18"
                    alt="">&nbsp;(19)&nbsp;9&nbsp;9211-4069</span></a></p>
          </li>
          <li>
            <p><a href="tel:1922232571"><span style="color:#ccc;font-size:18px"><img src="@/assets/phone-32.svg"
                    width="18" height="18" alt="">&nbsp;(19)&nbsp;2223-2571</span></a></p>
          </li>

          <li>
            <p><a href="mailto:contato@campinasredesdeprotecao.com.br"><span style="color:#ccc;font-size:18px"><img
                    src="@/assets/envelope-32.svg" width="18" height="18"
                    alt="">&nbsp;contato@campinasredesdeprotecao.com.br</span></a></p>
          </li>

          <h5 class="txt-normal">Siga-nos</h5>
          <div class="ilflex">
            <li><a href="https://www.instagram.com/campinasredesdeprotecao/"><span><img
                    src="@/assets/instagram-logo-32.svg" width="32" height="32"></span></a></li>&nbsp;
            <li><a href="https://www.facebook.com/campinasredesdeprotecao/"><span><img src="@/assets/facebook-logo-32.svg"
                    width="32" height="32"></span></a></li>

          </div>
        </ul>
      </div>
    </section>
      <div>
        <p class="copy">Copyright @ {{ year }} - Todos os direitos reservados</p>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: 'Footer',
  data: function () {
    return {
      year: ''
    }
  },
  created () {
    this.year = new Date().getFullYear()
  }
}
</script>
<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang='scss'>
* {
  text-align: left;
  color: #ccc!important;
  max-width: 90%!important;
}
.copy{
  text-align: center!important;
}
ul,li,p{
    margin: 0 !important;
    padding: 0 !important;
}
a:hover {
  color: #ccc;
  text-decoration: none
}

.col_white_amrc {
  color: #FFF
}

footer {
  min-width: 100vw !important;
  background-color: #263238;
  position: absolute;
  left: 0;
  min-height: 250px;
  padding-left: 1rem!important;
  section{
    margin: 0 !important;
    padding: 0 !important;
    display: grid;
    grid-template-columns: repeat(3, calc((100%)/3));

    @media only screen and (max-width: 600px) {
      grid-template-columns: repeat(1, 100vw)
    }
  }
}

footer p {
  font-size: 13px;
  color: #CCC
}

.footer_ul_amrc {
  font-size: 14px
}

.footer_ul_amrc li {
  padding: 0px 0px 5px 0px
}

.footer_ul_amrc li a {
  color: #CCC
}

.footer_ul_amrc li a:hover {
  color: #fff;
  text-decoration: none
}

.fleft {
  float: left
}

.footer_ul2_amrc {
  list-style-type: none;
  padding: 0px
}

.footer_ul2_amrc li p {
  display: table
}

.bottom_border {
  border-bottom: 1px solid #323f45
}

.foote_bottom_ul_amrc {
  list-style-type: none;
  display: table
}

.ilflex {
  display: inline-flex
}

.foote_bottom_ul_amrc li {
  display: inline
}

.foote_bottom_ul_amrc li a {
  color: #d71e1e
}

.social_footer_ul {
  display: table;
}

.social_footer_ul li {
  padding-left: 20px;
  padding-top: 10px;
  float: left
}

.social_footer_ul li a {
  color: #d71e1e;
  border: 1px solid #CCC;
  padding: 8px;
  border-radius: 50%
}

.social_footer_ul li i {
  width: 20px;
  height: 20px;
  text-align: center
}

@media (max-width: 991px) {
  .foote_bottom_ul_amrc {
    display: block
  }
}

.btn-whats {
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
  position: fixed;
  bottom: 7%;
  @media only screen and (max-width: 600px) {right: 5%;}
  right: 3%;
  color: #fff;
  font-size: 1.5em;
  display: block;
  width: 56px;
  height: 56px;
  border: none;
  border-radius: 50%;
  background: #01C854;
  cursor: pointer;
  z-index: 9999;
  box-shadow: 0 0 0 0 rgba(1, 200, 84, 0.5);
  -webkit-animation: pulse 1.5s infinite
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7)
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0)
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0)
  }
}</style>
