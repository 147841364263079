<template>
  <main>
  <header>
  <Nav/>
  </header>
  <router-view/>
  <Footer/>
</main>
</template>
<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'App',
  components: {
    Nav,
    Footer
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,900&display=swap');
main{
  max-width: 90vw!important;
  margin:0 auto!important;
  display: grid;
  @media only screen and (max-width: 600px) {
    width:100vw!important;
    margin:0!important;
  }
}
p{
    @media only screen and (max-width: 600px) {
      width: 90%!important;
      margin:0 auto!important;

    }
}
.section-app{
  display: grid;
}
.section-router{
  margin: 7rem 0!important;
}
.tag-desc{
  float: left;
  min-width: 100%!important;
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #16153D !important;
    font-size: 40px;
    font-size: 2.6666666666667rem;
    font-weight: 700;
    font-family: 'Montserrat',sans-serif;
}
.txt-normal{
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Montserrat',sans-serif;
}
.txt-pers{
  font-size: 2.6666666666667rem;
    font-weight: 700;
    font-family: 'Montserrat',sans-serif;
}
*{
  list-style: none;
  text-decoration: none;
  box-sizing: border-box!important;
}
p{
  text-align: left;
}
#app , html , body{
  margin: 0!important;
  padding: 0!important;
  max-width: 100vw!important;
  min-width: 100%!important;
  overflow-x: hidden;
}
body{
  background: #f6f6f6;
}
strong{
  margin: 0!important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 100vw!important;
}
h1,h2,h3,h4,h5,h6{
  color: orange;
}
</style>
